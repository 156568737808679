import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler, Annotation } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Filler, annotationPlugin);

const LineChart = ({ data }) => {
  const [pointSize, setPointSize] = useState(5);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const adjustPointSize = () => setPointSize(Math.max(3, window.innerWidth / 250));
    adjustPointSize();
    window.addEventListener('resize', adjustPointSize);
    return () => window.removeEventListener('resize', adjustPointSize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  const getColor = (value) => {
    if (value <= 0.11) return 'green';
    if (value <= 0.165) return 'orange';
    if (value <= 0.22) return 'red';
    return 'purple';
  };

  const gradientBackground = (ctx, values) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
    gradient.addColorStop(0, getColor(Math.max(...values)));
    gradient.addColorStop(1, getColor(Math.min(...values)));
    return gradient;
  };

  const chartData = {
    labels: data.map((item) =>
      new Date(item.datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    ),
    datasets: [
      {
        label: 'Precio',
        data: data.map((item) => item.value),
        borderColor: (ctx) => gradientBackground(ctx.chart.ctx, data.map((item) => item.value)),
        borderWidth: 2,
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        pointBackgroundColor: data.map((item) => getColor(item.value)),
        pointBorderColor: '#fff',
        pointRadius: pointSize,
        pointHoverRadius: pointSize + 2,
      },
    ],
  };

  const thresholds = [
    { value: 0.11, color: 'green', label: 'Bajo' },
    { value: 0.165, color: 'orange', label: 'Moderado' },
    { value: 0.22, color: 'red', label: 'Alto' },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw.toFixed(4);
            return `Precio: €${value}`;
          },
          footer: (tooltipItems) =>
            ``,
        },
      },
      annotation: {
        annotations: thresholds.map((threshold) => ({
          type: 'line',
          yMin: threshold.value,
          yMax: threshold.value,
          borderColor: threshold.color,
          borderWidth: 2,
          label: {
            content: threshold.label,
            enabled: true,
            position: 'end',
            backgroundColor: threshold.color,
          },
        })),
      },
    },
    scales: {
      x: {
        title: { display: true, text: 'Hora' },
        ticks: { maxTicksLimit: 10 },
      },
      y: {
        title: { display: true, text: 'Precio (€)' },
        beginAtZero: true,
        ticks: {
          callback: (value) => `€${value}`,
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  return (
    <div style={{ position: 'relative', height: '400px', width: '100%' }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineChart;

